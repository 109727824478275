import * as React from 'react';

import Layout from '@components/common/Layout';
import Seo from '@components/common/Seo';

const NotFoundPage = () => (
  <Layout segmentId="" language="en">
    <Seo title="404: Not found" lang="en" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
